<template>
  <div class="form-elements">
    <div class="row">
      <div class="col-md-12">
        <vuestic-widget :loading="loading">
          <template slot="header">
            <div class="w-100 d-flex justify-content-between align-items-center">
              <span class="title">
                Whitelabel Domains
              </span>
              <div class="d-flex justify-content-end align-items-center">
                <div class="searchForm">
                  <text-input name="search" v-model="search" placeholder="Search..."></text-input>
                  <button class="btn btn-default btn-primary btn-sm ml-2" @click="handleSearch()" :disabled="!search">
                    <i class="fa fa-search"></i> Filter
                  </button>
                  <button class="btn btn-default btn-primary btn-sm ml-2" @click="clearSearch()">
                    Reset
                  </button>
                </div>
              </div>
            </div>
          </template>
          <datatable v-bind="datatable" class="le-datatable">
          </datatable>
        </vuestic-widget>
      </div>
    </div>
  </div>
</template>
<script>
import Vue from 'vue'
import TdBoolean from '@/components/common/tables/comps/td-Boolean'
import TdNestedDateValue from '@/components/common/tables/comps/NestedDateValue'
import WhitelabelActions from './WhitelabelActions'

export default {
  data () {
    return {
      datatable: {
        HeaderSettings: false,
        pageSizeOptions: [10, 25, 50, 100],
        columns: (() => {
          const cols = [
            { title: '#', field: 'id', label: '#' },
            { title: 'Domain', sortable: true, field: 'domain'},
            { title: 'Vhost exists', tdComp: TdBoolean, sortable: true, field: 'is_vhost_exists' },
            { title: 'Cert exists', tdComp: TdBoolean, sortable: true, field: 'is_ssl_cert_exists' },
            { title: 'CreatedAt', tdComp: TdNestedDateValue, sortable: true, field: 'created_at' },
            { title: 'LastRequestAt', tdComp: TdNestedDateValue, sortable: true, field: 'last_request_cert_at' },
            { title: 'Actions', field: '', tdComp: WhitelabelActions },
          ]
          return cols
        })(),
        data: [],
        selection: [],
        query: {
          search: null,
        },
        total: 0,
        xprops: {
          eventbus: new Vue(),
        },
      },
      loading: false,
      search: null,
    }
  },

  mounted () {
    this.datatable.xprops.eventbus.$on('requestCert', this.requestCert)
  },

  watch: {
    'datatable.query': {
      handler () {
        this.handleQueryChange()
      },
      deep: true,
    },
  },

  methods: {
    handleSearch () { 
      this.datatable.query.search = this.search
    },

    clearSearch () {
      this.search = null
      this.datatable.query.search = null
    },

    handleQueryChange () {
      this.loading = true
      this.$store.dispatch('whitelabel/getWhitelabels', this.datatable.query)
        .then(res => {
          this.loading = false
          this.datatable.data = res.data.data
          this.datatable.total = res.data.meta.total
        })
        .catch(errors => {
          this.loading = false
        })
    },

    requestCert (whitelabel) {
      this.loading = true
      this.$store.dispatch('whitelabel/requestCert', whitelabel.id)
        .then(res => {
          this.handleQueryChange()
        })
        .catch(errors => {
          this.loading = false
        })
    },
  },
}
</script>
